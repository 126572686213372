import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import styled from 'styled-components'
import Modal from '../components/modal'
import Contact from '../components/contactForm'
import { GreyBtn, md } from '../common'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Wrap>
    <Card>
      <h1 css={`text-transform:uppercase;`}>Watch TRADE-INS</h1>
      <br />
      <p>We are often asked about taking Rolex Watches in trade toward new Rolex Watches. This is something that we address on a case by case basis. The answer is almost always yes, we will offer you a trade value for your old watch. Since Rolex watches are the only watch brand with an almost guaranteed resale value anywhere in the world we can usually offer something for almost any watch of any age. In the case of older watches it is not unusual for the trade value to exceed the original purchase price in some cases.</p>
      <p>The process is simple for you:</p>
      <p>Select the new watch you want to purchase and leave your watch with us for a day or two to inspect and evaluate.</p>
      <p>Once we’ve determined the value of your watch on the used market we will let you know how much we can allow in trade toward the new watch.</p>
      <GreyBtn onClick={handleClick}>Trade In</GreyBtn>
    </Card>

    <Modal show={ show } toggle={ handleClick } mobile={ true }>
      <Contact />
    </Modal>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/TradeIn/watchtradein-bg2.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Card = styled.div`
  width:60%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  ${md} {
    width: 90%;
    margin:0;
  }
`
