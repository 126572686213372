import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import styled from 'styled-components'
import Modal from '../components/modal'
import Contact from '../components/contactForm'
import { GreenBtn, md } from '../common'


export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Wrap>
    <Card>
      <h1>SELLING YOUR JEWELRY</h1>
      <br />
      <p>If you have jewelry or watches that you no longer wear and are interested in selling them for our Estate Collection, we are interested in seeing them! You can drop your items off at any location where we will take a photo, type up a detailed intake form, insure it for a value that is mutually agreeable and give you a receipt. Our buyers are Certified Gemologists and will assess your pieces and make you the best offer that is fair for everyone. Once we determine the highest offer it will be up to you if you decide to sell your piece of jewelry or not. In many cases if you choose to accept the amount offered for your jewelry, you have the option to sign your check over to Underwood’s and we will give you 25% more to purchase new jewelry from us. For example, if you’re offered $1000 for your old necklace and would like to buy a new one from us, you would have $1250 to spend. This process usually takes 5-7 business days.</p>
      <GreenBtn onClick={handleClick}>Trade In</GreenBtn>
    </Card>

    <Modal show={ show } toggle={ handleClick } mobile={ true }>
      <Contact />
    </Modal>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/TradeIn/jewelrytradein-bg2.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  h1 {
    line-height:32px;
  }

  ${md} {
    padding: 1rem;
  }
`

const Card = styled.div`
  width:60%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  ${md} {
    width: 90%;
    margin:0;
  }
`
