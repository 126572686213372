import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import DiamondTrade from '../sections/ts-diamondTrade'
import WatchTrade from '../sections/ts-watchTrade'
import Selling from '../sections/ts-sell'
import GreenBar from '../sections/watch-greenBar4'
import { PageHead } from '../common'

export default () => (
  <Layout>
    <SEO
      title="Trade in & Up"
      description="Underwood’s has several options for trading in your old jewelry or Rolex watches. Our experts look at every trade on a case by case basis, and will advise you to make the best decision." />
    <PageHead>
      <h1>TRADE IN & UP</h1>
      <p>We are often asked by our friends and customers, “What can I do with this piece of jewelry I no longer love or want to wear. Can I sell it or trade it for another piece of jewelry?” There are several options available to help turn your old piece of jewelry into a new piece of jewelry. One is to see if you can use your older piece of jewelry to trade toward a new piece of jewelry. Another option is to see if we can help you sell your older piece outright for a check or credit.</p>
    </PageHead>
    <GreenBar />
    <Selling />
    <DiamondTrade />
    <WatchTrade />
    <JoinUs />
  </Layout>
)
