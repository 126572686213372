import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import styled from 'styled-components'
import Modal from '../components/modal'
import Contact from '../components/contactForm'
import { GreyBtn, md, colors } from '../common'

const Copy = styled.div`
  width:60%;
  margin-left:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Wrap>
  <Copy>
    <h1>DIAMOND TRADE-INS</h1>
    <br />
    <p>Underwood’s offers an extremely liberal trade in policy that we have advertised as our, “Grow a Diamond” program in Jacksonville since the 1980’s. This program applies to loose diamonds, the center diamonds in engagement rings, diamond stud earrings and solitaire diamond stud pendants. The program is very simple, we guarantee the purchase price of whatever you paid for your Underwood's diamond toward the purchase of any diamond of greater value. There are virtually no restrictions. Whatever you paid gets applied toward the purchase of the more valuable diamond whether you make that trade toward a new diamond valued at $1 more or $10,000 more. You can make this trade at any point in the future without restriction. It doesn’t get much easier than that! If you have a diamond that you did not purchase from Underwood’s, while we cannot guarantee a trade, we may still be able to take it in trade toward a beautiful Underwood’s diamond. In this case our gemologists inspect and grade your diamond to see where it fits compared to the quality offered in our stores, then we determine a value that makes sense and is fair to all concerned, with the only stipulation being you have to trade up in value.</p>
    <GreyBtn onClick={handleClick}>Trade In</GreyBtn>
  </Copy>

  <Modal show={ show } toggle={ handleClick } mobile={ true }>
    <Contact />
  </Modal>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/TradeIn/diamondtradein-bg2.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: ${colors.greyDarkest};
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`
